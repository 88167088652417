import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

/**
 * props
 * name: name属性名
 * required: (true or false)
 */
const InputTextArea = (props) => {
  const questionName = props.name;
  const required = props.required;
  const selectedValue = props.selectedValue;
  const errorMessage = props.errorMessage;

  const handleTextInput = (name, text) => {
    props.onTextInput({name, text});
  }

  const id = questionName;
  const element = <div className="field" key={id}>
                    <textarea style={{fontSize: "16px"}}
                              rows="6" cols="32"
                              onChange={(event) => handleTextInput(questionName, event.target.value)} />
                  </div>

  return (
    <div>
      {
        required && <div className="field has-text-danger">
        ※ 必ず入力してください
        </div>
      }

      {element}
      
      {
        errorMessage && <div className="field has-text-danger">
        <FontAwesomeIcon icon={faExclamationCircle} /> { errorMessage }
        </div>
      }
    </div>
  );
}

export default InputTextArea;