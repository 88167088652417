import React, { useState, Suspense, useEffect } from "react"
import { navigate } from "gatsby"
import queryString from 'query-string'

import Enquete from "@components/pages/enquete/Enquete"
import EnqueteService from "@service/EnqueteService"
import LoadingScreen from "@components/parts/LoadingScreen"

import { Router } from '@reach/router'

const IndexPage = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [template, setTemplate] = useState([]);
  const params = queryString.parse(props.location.search);
  const enqueteId = params.id;
  const userId = params.userId;

  useEffect(()=>{
    EnqueteService.readCache(enqueteId).then((template) => {
      setTemplate(template);
      setLoading(false);
    });
  }, []);
  
  return (
    <LoadingScreen isLoading={isLoading}>
      <Enquete template={template} onPost={(values) => handlePost(enqueteId, userId, values)} />
    </LoadingScreen>
  )
}

const handlePost = (enqueteId, userId, selectedValues) => {
  EnqueteService.postAnswer(enqueteId, userId, selectedValues).then(result => {
    console.log(result, 'api success');
    navigate(
      "/complete",
      { replace: true }
    )
  }).catch((error) => {
    console.log(error);
  })
}

export default IndexPage
