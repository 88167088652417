import firebase from "firebase/app"
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/database'

const config = {
  apiKey: 'AIzaSyCX_FGHwturiBxv6rLq5u47quRr9pUi7m8',
  projectId: 'mmr-stg-enquete-260307',
  authDomain: "mmr-stg-enquete-260307.firebaseapp.com",
  storageBucket: 'mmr-stg-enquete-260307.appspot.com',
};

let functions = null;
if (!firebase.apps.length) {
  const fireApp = firebase.initializeApp(config);
  functions = fireApp.functions('asia-northeast1');
  // functions.useFunctionsEmulator('http://localhost:5000');
}
export {firebase, functions}
