import React, { useState, Suspense } from "react"
import Loader from 'react-loader-spinner'
import Styles from './LoadingScreen.module.scss'

export default (props) => {
  if (props.isLoading) {
    return (
      <div className={Styles.centered}>
        <Loader
          type="CradleLoader"
          color="#00BFFF"
          height={100}
          width={100}
        />
      </div>
    )
  }
  return props.children;
}