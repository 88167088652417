import React, { useState, Suspense } from "react"
import UnicornImg from "@assets/placeholder/icons/unicorn.svg"
import SelectRadio from "./SelectRadio"
import SelectCheckbox from "./SelectCheckbox"
import InputTextArea from "./InputTextArea"
import InputText from "./InputText"

import { isEmpty } from "@utils/util"

const Enquete = (props) => {
  return <EnqueteQuestions {...props} />
}

const EnqueteQuestions = (props) => {
  const [selectedValues, setSelectedValue] = useState({});
  const [errorMessages, setErrorMessage] = useState({});
  const template = props.template;

  // ラジオボタンの選択イベントハンドラ
  const handleSelect = (event) => {
    const name = event.name;
    const value = event.value;
    const text = event.text;
    const tmp = Object.assign({}, selectedValues);
    tmp[name] = {value, text};
    setSelectedValue(tmp);
    validateIndividual(name, text);
  }

  // ラジオボタンに付随するテキストボックスの入力イベントハンドラ
  const handleRadioTextInput = (event) => {
    const name = event.name;
    const optionValue = event.optionValue;
    const optionText = event.optionText;
    const inputValue = event.inputValue;

    if (!selectedValues[name]) {
      return;
    }

    // 入力されたテキストボックスに対応するチェックボックス値を探し、フリー入力項目として追加する
    const tmp = Object.assign({}, selectedValues);
    tmp[name]['freeInput'] = inputValue

    setSelectedValue(tmp);
  }

  // チェックボックスの選択値変更イベントハンドラ
  const handleCheckboxChange = (event) => {
    const name = event.name;
    const value = event.value;
    const text = event.text;
    const checked = event.checked;
    const tmp = Object.assign({}, selectedValues);

    // チェックボックスの選択値を保存
    if (!tmp[name]) {
      tmp[name] = []
    }

    if (checked) {
      tmp[name].push({value, text})
    } else {
      tmp[name] = tmp[name].filter(v => v.value !== value)
    }

    setSelectedValue(tmp);
    validateIndividual(name, tmp[name].join(','));
  }

  // チェックボックスに付随するテキストボックスの入力イベントハンドラ
  const handleCheckboxTextInput = (event) => {
    const name = event.name;
    const optionValue = event.optionValue;
    const optionText = event.optionText;
    const inputValue = event.inputValue;

    if (!selectedValues[name]) {
      return;
    }

    // 入力されたテキストボックスに対応するチェックボックス値を探し、フリー入力項目として追加する
    const tmp = Object.assign({}, selectedValues);
    for (const checkedItem of tmp[name]) {
      if (checkedItem.value === optionValue) {
        checkedItem['freeInput'] = inputValue
      }
    }

    setSelectedValue(tmp);
  }

  // テキストボックスの入力イベントハンドラ
  const handleTextInput = (event) => {
    const name = event.name;
    const text = event.text;
    const tmp = Object.assign({}, selectedValues);
    tmp[name] = text;
    setSelectedValue(tmp);
  }

  const handlePost = () => {
    const isValid = validateAll();
    if (isValid) {
      props.onPost(selectedValues);
    }
  }

  const validateIndividual = (name, text) => {
    const question = template.questions.find(template => template.name == name);
    if (!question) {
      return;
    }

    const tmp = Object.assign({}, errorMessages);
    delete tmp[name];
    let errorMessage = getErrorMessage(question, text);
    if (errorMessage) {
      tmp[name] = errorMessage;
    }
    setErrorMessage(tmp);
  }

  const validateAll = () => {
    const validateErrors = template.questions.reduce((acc, question) => {
      let target = null;
      if (selectedValues[question.name]) {
        if (question.type === 'checkbox') {
          target = selectedValues[question.name].join(',')
        } else if (question.type === 'radio') {
          target = selectedValues[question.name].text;
        } else {
          target = selectedValues[question.name];
        }
      }
      const errorMessage = getErrorMessage(question, target);
      if (errorMessage) {
        acc[question.name] = errorMessage;
      }
      return acc;
    }, {});
    console.log(validateErrors, 'validation errors');
    setErrorMessage(validateErrors);
    return Object.keys(validateErrors).length === 0;
  }

  const isFreeInputEmpty = (val) => {
    if (val) {
      if (val.includes('#{}')) {
        return true;
      }
    }
    return false;
  }

  /**
   * Questionテンプレートに従って入力値をチェックし、エラーメッセージを返す。
   * 正常値の場合はnullを返す。
   * @param {Object} question Questionテンプレート
   * @param {*} value チェック対象の入力値
   */
  const getErrorMessage = (question, value) => {
    if (question.required) {
      if (isFreeInputEmpty(value)) {
        return '入力してください';
      }
      if (isEmpty(value)) {
        if (question.type == 'text') {
          return '入力してください';
        } else {
          return '選択してください';
        }
      }
    }
    return null;
  }

  const questionElements = template.questions.map((question) => {
    return <div className="card is-non-box-shadow has-padding-bottom-5" key={question.name}>
      <div className="card-content">

        <div className="media is-flex is-vcentered">
          {/*
          <div className="media-left">
            <figure className="image is-64x64"><img className="is-rounded" src="https://bulma.dev/placeholder/pictures/bg_circle.svg?primary=00d1b2" alt="" /></figure>
          </div>
          */}
          <div className="media-content">
            <h3 className="title is-5">{question.title}</h3>
          </div>
        </div>

        {/** 各Questionの種別に応じて以下の要素が連続する */}
        {question.type == 'radio' &&
          <div className="columns is-mobile is-centered">
            <SelectRadio name={question.name}
                          selectedValue={selectedValues[question.name]}
                          options={question.options}
                          required={question.required}
                          errorMessage={errorMessages[question.name]}
                          onSelect={handleSelect}
                          onTextInput={handleRadioTextInput} />
          </div>
        }

        {question.type == 'checkbox' &&
          <div className="columns is-mobile is-centered">
            <SelectCheckbox name={question.name}
                            selectedValues={selectedValues[question.name]}
                            options={question.options}
                            required={question.required}
                            errorMessage={errorMessages[question.name]}
                            onChange={handleCheckboxChange}
                            onTextInput={handleCheckboxTextInput} />
          </div>
        }

        {question.type == 'textarea' &&
          <div className="is-mobile is-centered">
            <InputTextArea name={question.name}
                           selectedValues={selectedValues[question.name]}
                           required={question.required}
                           errorMessage={errorMessages[question.name]}
                           onTextInput={handleTextInput} />
          </div>
        }

        {question.type == 'text' &&
          <div className="is-mobile is-centered">
            <InputText name={question.name}
                       selectedValues={selectedValues[question.name]}
                       required={question.required}
                       errorMessage={errorMessages[question.name]}
                       onTextInput={handleTextInput} />
          </div>
        }
      </div>
    </div>
  });

  return (
    <div className="container">

      <section className="section has-padding-bottom-15">
        <div className="container has-text-centered">
          <h2 className="title">{template.header.title}</h2>
          <p dangerouslySetInnerHTML={{__html: template.header.description}} />
        </div>
      </section>
                
      <section className="section is-paddingless">
        <div className="container">
          <div className="block">
            { questionElements }
          </div>
          <div className="buttons is-centered has-margin-bottom-30">
            <button className="button is-medium is-danger is-outlined" onClick={handlePost}>アンケート回答</button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Enquete;
