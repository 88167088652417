import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { isEmpty } from "@utils/util"

/**
 * props
 * name: name属性名
 * options: { text: '選択項目テキスト', value: '選択項目値' }
 * required: (true or false)
 */
const SelectRadio = (props) => {
  const questionName = props.name;
  const options = props.options;
  const required = props.required;
  const selectedValue = props.selectedValue ? props.selectedValue.value : '';
  const errorMessage = props.errorMessage;

  const [freeOptionValue, setFreeOptionValue] = useState('');
  const [freeInputValue, setFreeInputValue] = useState('');


  const handleRadioSelect = (name, option) => {
    const optionValue = option.value;
    const optionText = option.text;
    const isFreeOption = option.freeOption;

    let resultText = optionText;
    if (isFreeOption) {
      if (!isEmpty(freeOptionValue)) {
        const optionTexts = optionText.split('#{}');
        resultText = optionTexts[0] + freeOptionValue + optionTexts[1];
      }
    }

    // 親コンポーネントに選択されたことを通知
    props.onSelect({
      name,
      value: optionValue,
      text: resultText
    });
  }

  const handleFreeOptionInput = (name, optionValue, optionText, inputValue) => {
    setFreeOptionValue(inputValue);
    if (selectedValue != optionValue) {
      return;
    }

    let resultText = optionText;
    if (!isEmpty(inputValue)) {
      const optionTexts = optionText.split('#{}');
      resultText = optionTexts[0] + inputValue + optionTexts[1];
    }

    // 親コンポーネントに選択されたことを通知
    props.onSelect({
      name,
      value: optionValue,
      text: resultText
    });
  }

  const handleFreeInput = (name, optionValue, optionText, inputValue) => {
    setFreeInputValue(inputValue);
    props.onTextInput({
      name,
      optionValue,
      optionText,
      inputValue
    })
  }

  const generateOptionLabel = (id, option) => {
    if (option.freeOption) {
      const optionTexts = option.text.split('#{}');
      return <label htmlFor={id}>
        {optionTexts[0]}
        &nbsp;<input type="number" style={{width: "60px", fontSize: "16px", transform: "scale(0.8)"}} onChange={(event) => handleFreeOptionInput(questionName, option.value, option.text, event.target.value)} />
        {optionTexts[1]}
      </label>
    }

    return <label htmlFor={id}>{option.text}</label>
  }

  const elements = options.map((option) => {
    const id = questionName + '-' + option.value;
    const isSelected = selectedValue === option.value;
    return  <div className="field" key={id}>
              <div>
                <input className="is-checkradio" type="radio" id={id}
                                                              name={questionName}
                                                              value={option.value}
                                                              checked={isSelected}
                                                              onChange={() => handleRadioSelect(questionName, option)} />
                { generateOptionLabel(id, option) }
              </div>
              {
                option.freeInput &&
                <div>
                  <textarea style={{fontSize: "16px"}}
                            rows="4" cols="32"
                            onChange={(event) => handleFreeInput(questionName, option.value, option.text, event.target.value)}
                            disabled={!isSelected} />
                </div>
              }
            </div>
    });
  

  return (
    <div>
      {
        required && <div className="field has-text-danger">
        ※ 必ず選択してください
        </div>
      }

      {elements}
      
      {
        errorMessage && <div className="field has-text-danger">
        <FontAwesomeIcon icon={faExclamationCircle} /> { errorMessage }
        </div>
      }
    </div>
  );
}

export default SelectRadio;