import { firebase } from '@utils/firebase'
import EnqueteAPI from "@api/EnqueteAPI"

const storage = firebase.storage();
let cache = null;

const readCache = (enqueteId) => {
  return firebase.storage().ref(`enquete-template/${enqueteId}.json`).getDownloadURL().then( url => {
    return fetch(url);
  }).then(response => {
    if (response.status !== 200) {
      throw new Error('Enquete Template Unresolved.');
    }
    return response.json();
  });
}

const postAnswer = (enqueteId, userId, answer) => {
  return EnqueteAPI.postAnswer(enqueteId, userId, answer);
}

export default {
  readCache,
  postAnswer
}