import { firebase, functions } from '@utils/firebase';

const postAnswer = (enqueteId, userId, answer) => {
  const apiCall = functions.httpsCallable('enqueteAnswer');
  return apiCall({
    enqueteId,
    userId,
    answer
  });
}

export default {
  postAnswer
}